@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-swal .swal2-input {
    padding: 10px;
    border: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 600px) {
    .swal2-popup {
        width: 90% !important; /* Makes the popup itself responsive */
    }
    .custom-input {
        width: calc(100% - 24px); /* Full width minus padding for mobile screens */
        max-width: none; /* Overrides any max-width set by SweetAlert */
    }
}
